<template>
  <div class="markup-tables flex">
    <va-card class="flex mb-4">
      <va-card-title style="font-size: 1.5rem;">
        Sessions&nbsp;
        <span>- {{ id }}</span>&nbsp;
      </va-card-title>
      <va-card-content>
        <div class="row">
          <va-select
            class="flex mb-2 md2"
            :options="filterTypes"
            v-model="filterType"
            label="Filter Types"
            placeholder="Select Type"
            :clearable="false"
          />
          <div class="flex mb-2 md3" v-if="filterType === 'Temporal'">
            <div class="row">
              <va-date-input
                class="flex mb-2 md8"
                v-model="filterDateStart"
                label="Start Date"
              />
              <va-time-input
                class="flex mb-2 md4"
                v-model="filterTimeStart"
              />
            </div>
            <div class="row" style="margin-top: -1.5rem; margin-bottom: -1.5rem;">
              <va-date-input
                class="flex mb-2 md8"
                v-model="filterDateEnd"
                label="End Date"
              />
              <va-time-input
                class="flex mb-2 md4"
                v-model="filterTimeEnd"
              />
            </div>
          </div>
          <va-select
            class="flex mb-2 md2"
            :options="usersSelect"
            v-model="filterText"
            label="Users"
            placeholder="Select User"
            :clearable="false"
            multiple
            v-if="filterType === 'User'"
          />
          <va-input
            class="flex mb-2 md2"
            placeholder="Filter..."
            v-model="filterText"
            v-on:keyup.enter="addFilter"
            v-else-if="filterType === 'All' || filterType === 'Name' || !filterType"
          />
          <div class="flex mb-2 md3 text-left" style="padding-right: 4rem;" v-if="(filterType !== 'Temporal' && filterText.length > 0) || (filterType === 'Temporal' && filterDateStart)">
            <va-icon :size="'2rem'" :name="'fa4-plus-square'" class="link" @click="addFilter" />
          </div>
          <div class="flex mb-2 md5 text-right" style="padding-right: 4rem;" v-if="selectedItems.length > 0 && (filterType !== 'Temporal' && filterText.length > 0)">
            <va-icon :size="'2rem'" :name="'material-icons-merge_type'" class="link" style="padding-right: 0.6rem;" @click="mergeMultiModal" />
            <va-icon :size="'1.8rem'" :name="'material-icons-grid_on'" style="cursor: pointer; color: #154ec1; margin-top: 0.25rem;" @click="visualizeImagesModal" />
            <va-icon :size="'2rem'" :name="'fa4-trash'" style="padding-right: 1rem; cursor: pointer; color: red;" @click="removeMultiModal" />
          </div>
          <div class="flex mb-2 md4 text-right" style="padding-right: 4rem;" v-else-if="selectedItems.length > 0 && (filterType === 'Temporal' && filterDateStart)">
            <va-icon :size="'2rem'" :name="'material-icons-merge_type'" class="link" style="padding-right: 0.6rem;" @click="mergeMultiModal" />
            <va-icon :size="'1.8rem'" :name="'material-icons-grid_on'" style="cursor: pointer; color: #154ec1; margin-top: 0.25rem;" @click="visualizeImagesModal" />
            <va-icon :size="'2rem'" :name="'fa4-trash'" style="padding-right: 1rem; cursor: pointer; color: red;" @click="removeMultiModal" />
          </div>
          <div class="flex mb-2 md7 text-right" style="padding-right: 4rem;" v-else-if="selectedItems.length > 0 && (filterType === 'Temporal' && !filterDateStart)">
            <va-icon :size="'2rem'" :name="'material-icons-merge_type'" class="link" style="padding-right: 0.6rem;" @click="mergeMultiModal" />
            <va-icon :size="'1.8rem'" :name="'material-icons-grid_on'" style="cursor: pointer; color: #154ec1; margin-top: 0.25rem;" @click="visualizeImagesModal" />
            <va-icon :size="'2rem'" :name="'fa4-trash'" style="padding-right: 1rem; cursor: pointer; color: red;" @click="removeMultiModal" />
          </div>
          <div class="flex mb-2 md8 text-right" style="padding-right: 4rem;" v-else-if="selectedItems.length > 0">
            <va-icon :size="'2rem'" :name="'material-icons-merge_type'" class="link" style="padding-right: 0.6rem;" @click="mergeMultiModal" />
            <va-icon :size="'1.8rem'" :name="'material-icons-grid_on'" style="cursor: pointer; color: #154ec1; margin-top: 0.25rem;" @click="visualizeImagesModal" />
            <va-icon :size="'2rem'" :name="'fa4-trash'" style="padding-right: 1rem; cursor: pointer; color: red;" @click="removeMultiModal" />
          </div>
        </div>
        <div class="row">
          <div class="flex xs12">
            <va-chip outline class="mb-2 mr-2" color="primary" v-for="(filter, i) in filters" v-bind:key="`chip${i}`">
              {{ filter }}&nbsp;<va-icon :size="'1rem'" :name="'fa4-remove'" style="cursor: pointer;" @click="removeFilter(i)" />
            </va-chip>
          </div>
        </div>
        <div class="row">
          <div class="flex xs12">
            <va-alert class="mt-3" color="primary" outline  v-if="selectedItemsAll.length > 0">
              <span>
                Number of selected items:
                <va-chip class="ml-2 chip-hover" @click="clearSelection">{{ selectedItemsAll.length }}</va-chip>
              </span>
            </va-alert>
          </div>
        </div>
        <va-data-table
          :items="filtered"
          :columns="columns"
          :per-page="perPage"
          :current-page="1"
          :selectable="true"
          :loading="isSessionsLoading"
          @sorted="sortChange"
          @selection-change="selectionChange"
          v-model="selectedItems"
        >
          <template #bodyAppend>
            <tr>
              <td colspan="4" class="table-example--pagination">
                <va-pagination
                  v-model="currentPage"
                  input
                  :pages="pages"
                />
              </td>
              <td colspan="3" class="table-example--pagination">
                <va-select
                  style="width: 8rem;"
                  v-model="perPage"
                  :label="'Per Page'"
                  :options="perPageOptions"
                  noClear
                />
              </td>
            </tr>
          </template>
          <template #cell(uid)="{ source: uid }">
            <router-link :key="uid" :to="{ name: 'files', params: { id, sessionId: uid }}">
              <va-icon :name="'fa4-eye'"/>
            </router-link>
            <va-icon :name="'fa4-trash'" style="padding-left: 1rem; cursor: pointer; color: red;" @click="removeModal(uid, label)" v-if="access !== 'User' || (access === 'User' && sessionUser[uid] === user_id)" />
          </template>
        </va-data-table>
        <va-alert class="mt-3" border="left" v-if="filters.length > 0">
          <span>
            Number of filtered items:
            <va-chip>{{ filteredCount }}</va-chip>
          </span>
        </va-alert>
      </va-card-content>
    </va-card>

    <va-modal
      v-model="removeModalShow"
      hide-default-actions
      overlay-opacity="0.2"
      size="large"
    >
      <template #header>
        <h2>Remove a session</h2>
      </template>
      <slot>
        <div>Do you want to remove {{ removeName }}?</div>
      </slot>
      <template #footer>
        <va-button class="mr-2 mb-2" color="gray" @click="removeModalShow = false">Cancel</va-button>
        <va-button class="mr-2 mb-2" color="danger" @click="removeModalConfirm">Delete</va-button>
      </template>
    </va-modal>

    <va-modal
      v-model="removeMultiModalShow"
      hide-default-actions
      overlay-opacity="0.2"
      size="large"
    >
      <template #header>
        <h2>Remove sessions</h2>
      </template>
      <slot>
        <div>Do you want to remove the following sessions?</div>
        <br />
        <p v-for="removeMultiName in removeMultiNames">{{ removeMultiName }}</p>
      </slot>
      <template #footer>
        <va-button class="mr-2 mb-2" color="gray" @click="removeMultiModalShow = false">Cancel</va-button>
        <va-button class="mr-2 mb-2" color="danger" @click="removeMultiModalConfirm">Delete All</va-button>
      </template>
    </va-modal>

    <va-modal
      v-model="visualizeModal"
      hide-default-actions
      overlay-opacity="0.2"
      size="large"
    >
      <template #header>
        <h2>Select a mode</h2>
      </template>
      <slot>
        <va-select
          class="flex"
          :options="modes"
          v-model="selectedItemsMode"
          label="Modes"
          placeholder="Select a mode"
          :clearable="false"
        />
      </slot>
      <template #footer>
        <va-button class="mr-2 mb-2" color="gray" @click="visualizeModal = false">Cancel</va-button>
        <va-button class="mr-2 mb-2" color="primary" @click="visualizeImages">Select</va-button>
      </template>
    </va-modal>

    <va-modal
      v-model="mergeMultiModalShow"
      hide-default-actions
      overlay-opacity="0.2"
      size="large"
    >
      <template #header>
        <h2>Merge sessions</h2>
      </template>
      <slot>
        <div>Do you want to merge the following sessions?</div>
        <br />
        <p v-for="removeMultiName in mergeMultiNames">{{ removeMultiName }}</p>
        <br />
        <va-select
          class="mb-3"
          label="Merge into"
          v-model="newId"
          textBy="label"
          track-by="uid"
          :options="selectedItems"
        />
      </slot>
      <template #footer>
        <va-button class="mr-2 mb-2" color="gray" @click="mergeMultiModalShow = false">Cancel</va-button>
        <va-button class="mr-2 mb-2" color="primary" @click="mergeMultiModalConfirm">Merge</va-button>
      </template>
    </va-modal>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'

export default {
  mounted: async function () {
    if (!this.loader) {
      this.loader = this.$loading.show()
    }
    this.$store.commit('setSelectedItems', [])
    this.sessionsObject = {}
    try {
      const headers = {
        Authorization: `Bearer ${this.token}`
      }
      // Load a list of sessions
      this.loadSessions()

      // Load Users
      const users = await axios.get(`${process.env.VUE_APP_API_URL}/api/users?per_page=0`, { headers })
      this.usersSelect = await Promise.all(users.data.users.map((user) => user.username))
      this.usersSelect.sort()
    } catch (error) {
      this.$router.push({ name: 'login', query: { redirect: `/pages/identifiers/${this.id}/sessions` } })
    }
    if (this.loader) {
      this.loader.hide()
    }
  },
  data () {
    return {
      loader: null,
      token: this.$store.state.token,
      id: this.$route.params.id,
      user_id: this.$store.state.uid,
      access: this.$store.state.access,
      sessionUser: {},
      sessions: [],
      filtered: [],
      sessionsObject: {},
      selectedItems: [],
      selectedItemsAll: [],
      columns: [
        {
          key: 'label',
          label: 'Sessions',
          headerTitle: 'Sessions',
          sortable: true
        },
        {
          key: 'user',
          label: 'User',
          headerTitle: 'User',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Created on',
          headerTitle: 'Created on',
          sortable: true
        },
        {
          key: 'uid',
          label: 'Action',
          headerTitle: 'Action'
        }
      ],
      filterText: '',
      filterDateStart: null,
      filterDateEnd: null,
      filterTimeStart: null,
      filterTimeEnd: null,
      filters: localStorage.getItem('sessions_filters') ? localStorage.getItem('sessions_filters').split(',') : [],
      filtersTypes: localStorage.getItem('sessions_filtersTypes') ? localStorage.getItem('sessions_filtersTypes').split(',') : [],
      filterTypes: ['All', 'Name', 'Temporal', 'User'],
      modes: ['Cross Polarized', 'Linear Polarized', 'LEDs Off'],
      selectedItemsMode: null,
      visualizeModal: false,
      filterType: null,
      usersSelect: [],
      perPage: localStorage.getItem('sessions_perPage') ? parseInt(localStorage.getItem('sessions_perPage')) : 10,
      perPageOptions: [5, 10, 25, 50, 100, 500, 1000],
      currentPage: localStorage.getItem('sessions_currentPage') ? parseInt(localStorage.getItem('sessions_currentPage')) : 1,
      removeModalShow: false,
      removeId: null,
      removeName: null,
      removeMultiModalShow: false,
      removeMultiNames: [],
      mergeMultiModalShow: false,
      mergeMultiNames: [],
      newId: null,
      pages: 0,
      sortBy: 'created_at',
      sortOrder: 'desc',
      isSessionsLoading: false,
      newLoad: false
    }
  },
  computed: {
    filteredCount () {
      return this.filtered.length
    }
  },
  watch: {
    currentPage (newCurrentPage) {
      localStorage.setItem('sessions_currentPage', newCurrentPage)
      this.loadSessions()
    },
    perPage (newPerPage) {
      localStorage.setItem('sessions_perPage', newPerPage)
      this.loadSessions()
    }
  },
  methods: {
    loadSessions: async function (filtering = false) {
      this.isSessionsLoading = true
      let selectedItemsAll = []
      if (!filtering) {
        selectedItemsAll = this.selectedItemsAll
      } else {
        this.selectedItems = []
      }
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        const filter_types = []
        const filter_values = []
        // Prepare filters
        this.filtersTypes.forEach((type, i) => {
          switch (type) {
            case 'All':
              filter_types.push('all')
              filter_values.push(this.filters[i])
              break
            case 'Name':
              filter_types.push('label')
              filter_values.push(this.filters[i])
              break
            case 'Temporal':
              filter_types.push('created_at')
              if (this.filters[i].split('to').length <= 1) {
                const start_time = moment(`${this.filters[i]} 00:00`, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm')
                const end_time = moment(`${this.filters[i]} 23:59`, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm')
                filter_values.push(`${start_time} to ${end_time}`)
              } else {
                const start_time = moment(this.filters[i].split(' to ')[0], 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm')
                const end_time = moment(this.filters[i].split(' to ')[1], 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm')
                filter_values.push(`${start_time} to ${end_time}`)
              }
              break
            case 'User':
              filter_types.push('user')
              filter_values.push(this.filters[i])
              break
            default:
              break
          }
        })

        let url = `${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}/sessions?page_no=${this.currentPage}&per_page=${this.perPage}&sort_by=${this.sortOrder === 'asc' ? this.sortBy : `-${this.sortBy}`}`
        if (filter_types.length > 0) {
          url = `${url}&filter_types=${filter_types.toString()}&filter_values=${filter_values.toString()}`
        }
        // Load a list of sessions
        const sessions = await axios.get(url, { headers })
        this.sessions = await Promise.all(sessions.data.sessions.map(async (session) => {
          session.created_at = moment.utc(session.created_at).toDate()
          session.created_at = moment(session.created_at).local().format('YYYY-MM-DD hh:mm a')
          this.sessionsObject[session.uid] = session.label
          this.sessionUser[session.uid] = session.user_id
          return session
        }))
        this.filtered = this.sessions
        this.pages = sessions.data.total_pages
      } catch (error) {
        this.$router.push({ name: 'login', query: { redirect: `/pages/identifiers/${this.id}/sessions` } })
      }
      this.isSessionsLoading = false
      this.newLoad = true
      this.selectedItemsAll = selectedItemsAll
    },
    removeModal (id) {
      this.removeId = id
      this.removeName = this.sessionsObject[id]
      this.removeModalShow = true
    },
    removeModalConfirm: async function () {
      if (!this.loader) {
        this.loader = this.$loading.show()
      }
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        // Delete a session
        await axios.delete(`${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}/sessions/${this.removeId}`, { headers })
        // Show success message
        this.$vaToast.init({
          message: 'Session deleted successfully!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })

        this.loadSessions(true)

        this.removeId = null
        this.removeName = null
        this.removeModalShow = false
      } catch (error) {
        this.removeId = null
        this.removeName = null
        this.removeModalShow = false
        this.$router.push({ name: 'login', query: { redirect: `/pages/identifiers/${this.id}/sessions` } })
      }
      if (this.loader) {
        this.loader.hide()
      }
    },
    removeMultiModal: async function () {
      this.removeMultiNames = []
      await Promise.all(this.selectedItemsAll.map((selectedItem) => {
        if (this.access !== 'User' || (selectedItem.user_id === this.user_id)) {
          this.removeMultiNames.push(selectedItem.label)
        }
      }))
      this.removeMultiModalShow = true
    },
    removeMultiModalConfirm: async function () {
      if (!this.loader) {
        this.loader = this.$loading.show()
      }
      try {
        // Delete sessions
        await Promise.all(this.selectedItemsAll.map((selectedItem) => {
          if (this.access !== 'User' || (selectedItem.user_id === this.user_id)) {
            this.removeItem(selectedItem.uid)
          }
        }))
        // Show success message
        this.$vaToast.init({
          message: 'Sessions deleted successfully!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })

        this.loadSessions(true)

        this.selectedItems = []
        this.selectedItemsAll = []
        this.removeMultiNames = []
        this.removeMultiModalShow = false
      } catch (error) {
        this.selectedItems = []
        this.selectedItemsAll = []
        this.removeMultiNames = []
        this.removeMultiModalShow = false
        this.$router.push({ name: 'login', query: { redirect: `/pages/identifiers/${this.id}/sessions` } })
      }
      if (this.loader) {
        this.loader.hide()
      }
    },
    removeItem (id) {
      const headers = {
        Authorization: `Bearer ${this.token}`
      }
      return axios.delete(`${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}/sessions/${id}`, { headers })
    },
    mergeMultiModal: async function () {
      this.mergeMultiNames = await Promise.all(this.selectedItemsAll.map((selectedItem) => selectedItem.label))
      this.mergeMultiModalShow = true
    },
    mergeMultiModalConfirm: async function () {
      if (this.newId) {
        if (!this.loader) {
          this.loader = this.$loading.show()
        }
        try {
          const headers = {
            Authorization: `Bearer ${this.token}`
          }
          const payload = {
            sessions: []
          }
          await Promise.all(this.selectedItemsAll.map((selectedItem) => {
            if (selectedItem.uid !== this.newId.uid) {
              payload.sessions.push(selectedItem.uid)
            }
          }))
          // Merge sessions
          await axios.post(`${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}/sessions/${this.newId.uid}/merge`, payload, { headers })
          // Show success message
          this.$vaToast.init({
            message: 'Sessions merged successfully!',
            iconClass: 'fa-star-o',
            position: 'bottom-right',
            duration: 6000,
            fullWidth: false,
            color: 'primary',
          })

          this.loadSessions(true)

          this.newId = null
          this.mergeMultiNames = []
          this.mergeMultiModalShow = false
        } catch (error) {
          this.newId = null
          this.mergeMultiNames = []
          this.mergeMultiModalShow = false
          this.$router.push({ name: 'login', query: { redirect: '/pages/sessions' } })
        }
        if (this.loader) {
          this.loader.hide()
        }
      }
    },
    addFilter: function () {
      switch (this.filterType) {
        case 'All':
        case 'Name':
          if (this.filterText && this.filterText.length > 0) {
            this.filters.push(this.filterText)
            this.filtersTypes.push(this.filterType)
          }
          break
        case 'Temporal':
          if (this.filterDateStart) {
            const startDate = new Date(this.filterDateStart).toISOString().split('T')[0]
            const endDate = this.filterDateEnd ? new Date(this.filterDateEnd).toISOString().split('T')[0] : null
            const startTime = this.filterTimeStart ? `${this.filterTimeStart.toString().split(' ')[4].split(':')[0]}:${this.filterTimeStart.toString().split(' ')[4].split(':')[1]}` : '00:00'
            const endTime = this.filterTimeEnd && this.filterDateEnd ? `${this.filterTimeEnd.toString().split(' ')[4].split(':')[0]}:${this.filterTimeEnd.toString().split(' ')[4].split(':')[1]}` : '23:59'
            let tempDate = `${startDate} ${startTime}`
            if (endDate) {
              tempDate = `${tempDate} to ${endDate} ${endTime}`
            } else {
              tempDate = startDate
            }
            this.filters.push(tempDate)
            this.filtersTypes.push(this.filterType)
          }
          break
        case 'User':
          if (this.filterText && this.filterText.length > 0) {
            this.filters.push(this.filterText.join(';').trim())
            this.filtersTypes.push(this.filterType)
          }
          break
        default:
      }
      localStorage.setItem('sessions_filters', this.filters)
      localStorage.setItem('sessions_filtersTypes', this.filtersTypes)
      this.filterText = ''
      this.filterType = ''
      this.loadSessions(true)
    },
    removeFilter: function (index) {
      this.filters.splice(index, 1)
      this.filtersTypes.splice(index, 1)
      localStorage.setItem('sessions_filters', this.filters)
      localStorage.setItem('sessions_filtersTypes', this.filtersTypes)
      this.loadSessions(true)
    },
    sortChange (sorted) {
      if (sorted.sortBy && sorted.sortingOrder && (this.sortBy !== sorted.sortBy || this.sortOrder !== sorted.sortingOrder)) {
        this.sortBy = sorted.sortBy
        this.sortOrder = sorted.sortingOrder
        this.loadSessions()
      }
    },
    selectionChange (selectedItems) {
      if (selectedItems.currentSelectedItems.length > selectedItems.previousSelectedItems.length) {
        const difference = selectedItems.currentSelectedItems.filter(x => !selectedItems.previousSelectedItems.includes(x))
        difference.forEach((item) => {
          this.selectedItemsAll.push(item)
        })
      } else {
        if (!this.newLoad) {
          const difference = selectedItems.previousSelectedItems.filter(x => !selectedItems.currentSelectedItems.includes(x))
          difference.forEach((item) => {
            _.remove(this.selectedItemsAll, item)
          })
        } else {
          this.sessions.forEach((session) => {
            this.selectedItemsAll.forEach((sessionSelected) => {
              if (session.uid === sessionSelected.uid) {
                this.selectedItems.push(session)
              }
            })
          })
          this.newLoad = false
        }
      }
    },
    clearSelection () {
      this.selectedItems = []
      this.selectedItemsAll = []
    },
    visualizeImagesModal () {
      this.visualizeModal = true
    },
    visualizeImages: async function () {
      this.$store.commit('setSelectedItems', this.selectedItems)
      this.$store.commit('setSelectedItemsMode', this.selectedItemsMode)
      this.$router.push({ name: 'grid' })
    }
  },
}
</script>

<style lang="scss">
  .markup-tables {
    .table-wrapper {
      overflow: auto;
    }

    .va-table {
      width: 100%;
    }
  }

  .table-example--pagination {
    text-align: right;
    text-align: -webkit-right;
    padding-top: 1rem;
  }

  .link {
     cursor: pointer;
     color: var(--va-primary);
  }

  .link:hover {
    text-decoration: underline;
  }

  .va-data-table {
    --va-data-table-thead-color: var(--va-primary);
  }

  .va-pagination__input {
    background-color: var(--va-white);
  }

  .va-alert {
    background-color: var(--va-white) !important;
  }

  .va-modal__dialog {
    background-color: var(--va-white);
    color: var(--va-dark);
  }

  .va-date-picker {
    --va-date-picker-text-color: var(--va-pure-dark);
  }

  .chip-hover:hover {
    cursor: pointer;
    background: var(--va-danger) !important;
  }
</style>
